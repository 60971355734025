import React from "react"

import Layout from "../components/layout"
import Home from "./home/home";

const IndexPage = () => (
  <Layout>
    <Home />
  </Layout>
)

export default IndexPage
